import React from 'react'
import { NextPage, GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import Head from 'next/head'
import styled from 'styled-components'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
/**
 * ui
 */
import Container from 'src/components/Layout/Container'
import Button, { ButtonSize, ButtonStyle } from '../components/Button/Button'
/**
 * images
 */
import Image404Src from 'public/assets/images/image-404.svg'
import WorkersImage from 'public/assets/images/workers.svg'

const Page404: NextPage = () => {
  const { t } = useTranslation('pages.404')
  const { push } = useRouter()

  const hrefToMain = React.useCallback(() => {
    return push('/')
  }, [push])

  return (
    <StyledContainer>
      <StyledMain>
        <Head>
          <title>{t('title')}</title>
        </Head>
        <Container>
          <StyledNotFoundPage>
            <StyledNotFoundTitle>{t('title')}</StyledNotFoundTitle>
            <StyledNotFoundText>{t('description')}</StyledNotFoundText>
            <StyledImage404>
              <Image404Src />
            </StyledImage404>
            <StyledStyledWorkers>
              <StyledWorkersImage />
            </StyledStyledWorkers>
            <StyledButtonWrapper>
              <StyledStyledButton>
                <Button
                  buttonStyle={ButtonStyle.FILLED}
                  size={ButtonSize.BLOCK}
                  onClick={hrefToMain}
                >
                  {t('btn')}
                </Button>
              </StyledStyledButton>
            </StyledButtonWrapper>
          </StyledNotFoundPage>
        </Container>
      </StyledMain>
    </StyledContainer>
  )
}

export default Page404

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['pages.404'])),
    },
  }
}

const StyledContainer = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  height: 100vh;
`

const StyledMain = styled.main`
  ${({ theme }) => theme.mixins.flex('1', '0', 'auto')}
  padding-top: 108px;
  overflow-x: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-top: 138px;
  }
`
const StyledNotFoundTitle = styled.h1`
  width: 230px;
  margin-bottom: 12px;
  ${({ theme }) => theme.mixins.H3}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: auto;
    margin: 10px 0 24px;
    ${({ theme }) => theme.mixins.H1}
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin: -35px 0 32px;
    ${({ theme }) => theme.mixins.H1}
  }
`

const StyledNotFoundText = styled.p`
  margin-bottom: 20px;
  ${({ theme }) => theme.mixins.textSmall}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal}
    margin-bottom: 0;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.textNormal}
    margin-bottom: 58px;
  }
`

const StyledButtonWrapper = styled.div`
  text-align: center;
  margin-top: 137px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 404px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 331px;
  }
`

const StyledImage404 = styled.div`
  width: 531px;
  max-width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    position: absolute;
    right: -30px;
    z-index: -2;
    top: 161px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    right: 0;
    top: -67px;
  }
`

const StyledNotFoundPage = styled.div`
  max-width: 320px;
  margin: 0 auto 0;
  position: relative;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 767px;
    margin-bottom: 200px;
    display: block;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: none;
    margin-top: 60px;
    margin-bottom: 0;
  }
`

const StyledStyledWorkers = styled.div`
  position: absolute;
  transform: scale(1.64) translateX(-25%);
  bottom: 44px;

  left: 50%;
  width: 100%;
  z-index: -1;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    bottom: -99px;
    transform: scale(1.63) translateX(-27.7%);
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    bottom: -69px;
    transform: scale(1) translateX(-50%);
  }
`

const StyledStyledButton = styled.div`
  width: fit-content;
  text-align: center;
  margin: auto;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 300px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 322px;
  }
`

const StyledWorkersImage = styled(WorkersImage)`
  max-width: 100%;
  width: 100%;
`
